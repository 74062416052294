import React from 'react';

import { Box, Container } from '@mui/material';

import About from './about';
import Header from './header';
import Methodology from './methodology';

function Home() {
  return (
    <>
      <Box sx={{ bgcolor: '#1A1A1A', pt: 2, pb: 6 }}>
        <Header />
      </Box>

      <Container id="Sobre" maxWidth="lg" sx={{ my: 10 }}>
        <About />
      </Container>

      <Box id="Serviços" sx={{ py: 8, bgcolor: '#1A1A1A' }}>
        <Methodology />
      </Box>

      <Box>
        <img src="/assets/images/despertando-sonhos-bottom.jpg" width="100%" />
      </Box>
    </>
  );
}

export default Home;
