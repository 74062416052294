import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from './footer';
import Header from './header';

function Main() {
  const { pathname } = useLocation();

  return (
    <>
      <Header />

      <Outlet />

      {pathname !== '/contatos' && <Footer />}
    </>
  );
}

export default Main;
