import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Stack } from '@mui/material';

import { H4 } from 'components/typography';

import Links from './links';

function MobileLinks({ isOpen, onClickLink }) {
  const height = window.screen.height;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'none',
        py: 3,
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        overflow: 'hidden',
        ...(isOpen && {
          height,
          display: 'flex',
        }),
      }}
    >
      <Stack
        spacing={3}
        sx={{
          minWidth: 200,
          display: 'none',
          background: '#1A1A1A',
          pl: 3,
          py: 2,
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          overflow: 'visible',
          boxShadow: '0px 1px 2px 0px rgba(60,64,67,.3), 0px 1px 3px 1px rgba(60,64,67,.15)',
          ...(isOpen && {
            display: 'flex',
            top: 0,
          }),
        }}
      >
        {Links.map((data, key) => (
          <Link key={key} onClick={() => onClickLink(data)}>
            <H4 sx={{ color: '#FCFCFC' }}>{data.title}</H4>
          </Link>
        ))}
      </Stack>
    </Box>
  );
}

export default MobileLinks;
