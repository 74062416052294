import React from 'react';
import { useRoutes } from 'react-router-dom';

import Main from 'layouts/main';

import Contact from 'views/contact';
import Home from 'views/home';

function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Main />,
      children: [
        { path: '', element: <Home /> },
        { path: '/contatos', element: <Contact /> },
      ],
    },
  ]);

  return routes;
}

export default Router;
