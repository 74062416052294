import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Container, Hidden, IconButton, Stack } from '@mui/material';

import { H4 } from 'components/typography';

import NavLinks from './links';
import MobileLinks from './mobileLinks';

function Header() {
  const { pathname } = useLocation();

  const [mobileLinks, setMobileLinks] = useState(false);

  function handleChangeMobileLinks() {
    const isOpen = mobileLinks !== true;
    setMobileLinks(isOpen);
  }

  function handleClickLink(link) {
    if (!link.path) {
      document.getElementById(link.title).scrollIntoView({
        behavior: 'smooth',
      });
      return;
    }

    window.location.href = link.path;
  }

  return (
    <Box sx={{ position: 'relative', bgcolor: '#1A1A1A', pb: 2 }}>
      <Container maxWidth="lg">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Link to="/">
            <img alt="logo" src="/assets/images/oj.png" style={{ width: 28, height: 42 }} />
          </Link>

          <Hidden mdUp>
            {pathname !== '/contatos' && (
              <IconButton sx={{ color: 'white', mt: 1, p: 0 }} onClick={handleChangeMobileLinks}>
                <MenuIcon />
              </IconButton>
            )}
          </Hidden>

          <Hidden mdDown>
            {pathname !== '/contatos' && (
              <Stack direction="row" spacing={3}>
                {NavLinks.map((data, key) => (
                  <Link key={key} onClick={() => handleClickLink(data)}>
                    <H4 sx={{ color: '#FCFCFC' }}>{data.title}</H4>
                  </Link>
                ))}
              </Stack>
            )}
          </Hidden>
        </Stack>
      </Container>

      <MobileLinks isOpen={mobileLinks} onClickLink={handleClickLink} />
    </Box>
  );
}

export default Header;
