import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Accordion, AccordionDetails, AccordionSummary, Container, Stack } from '@mui/material';

import { H1, H3 } from 'components/typography';

function Methodology() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg">
      <Stack spacing={3}>
        <H3 color="white">METODOLOGIA /</H3>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          spacing={{ xs: 3, md: 2 }}
        >
          <img src="/assets/images/design-de-logotipo.png" style={{ width: 120 }} />

          <H3 color="white" fontWeight="normal">
            Briefing, pesquisa, mapa mental, estudo das cores, composição, aplicabilidade e tudo que
            auxilie no resultado final. No meu ambiente de trabalho utilizo diversas ferramentas de
            criação para redirecionar o trabalho de forma gradual e consistente, resultando em um
            projeto único e memorável.
          </H3>
        </Stack>
      </Stack>

      <Stack sx={{ mt: 8 }}>
        <Accordion
          expanded={expanded === 'BRIEFING'}
          sx={{
            bgcolor: 'transparent',
            borderTop: '1px solid #FCFCFC',
            borderBottom: '1px solid #FCFCFC',
            boxShadow: 'none',
          }}
          square
          disableGutters
          onChange={handleChange('BRIEFING')}
        >
          <AccordionSummary
            sx={{ '& .MuiAccordionSummary-expandIconWrapper': { color: 'white' } }}
            expandIcon={expanded === 'BRIEFING' ? <RemoveIcon /> : <AddIcon />}
          >
            <H1 color="white">BRIEFING</H1>
          </AccordionSummary>

          <AccordionDetails>
            <H3 color="white" fontWeight="normal" sx={{ mb: 3 }}>
              Um serviço exclusivo que captura informações essenciais para projetos de design
              eficientes. Com análise detalhada de metas e direcionamentos do cliente. Essa base
              sólida guia todo o processo criativo, permitindo soluções personalizadas e comunicação
              clara.
            </H3>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'CAMINHADA'}
          sx={{
            bgcolor: 'transparent',
            borderTop: '1px solid #FCFCFC',
            borderBottom: '1px solid #FCFCFC',
            boxShadow: 'none',
          }}
          square
          disableGutters
          onChange={handleChange('CAMINHADA')}
        >
          <AccordionSummary
            sx={{ '& .MuiAccordionSummary-expandIconWrapper': { color: 'white' } }}
            expandIcon={expanded === 'CAMINHADA' ? <RemoveIcon /> : <AddIcon />}
          >
            <H1 color="white">CAMINHADA</H1>
          </AccordionSummary>

          <AccordionDetails>
            <H3 color="white" fontWeight="normal" sx={{ mb: 3 }}>
              Nosso processo de criação de marca é estruturado e eficiente. Através de uma análise
              detalhada do briefing, combinando criatividade, habilidades técnicas e conhecimento,
              definimos vários pontos importantes em nossa caminhada em direção à criação de uma
              marca forte e impactante.
              <br />
              <br />
              Buscamos superar as expectativas dos clientes e criar designs únicos que se destacam
              da concorrência.
            </H3>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'DESPERTAR'}
          sx={{
            bgcolor: 'transparent',
            borderTop: '1px solid #FCFCFC',
            borderBottom: '1px solid #FCFCFC',
            boxShadow: 'none',
          }}
          square
          disableGutters
          onChange={handleChange('DESPERTAR')}
        >
          <AccordionSummary
            sx={{ '& .MuiAccordionSummary-expandIconWrapper': { color: 'white' } }}
            expandIcon={expanded === 'DESPERTAR' ? <RemoveIcon /> : <AddIcon />}
          >
            <H1 color="white">DESPERTAR</H1>
          </AccordionSummary>

          <AccordionDetails>
            <H3 color="white" fontWeight="normal" sx={{ mb: 3 }}>
              Esteja pronto para testemunhar o despertar glorioso de uma marca. Após um intenso
              processo de criação, estaremos próximos de revelar sua nova identidade vibrante e
              poderosa.
              <br />
              <br />
              Como um fênix emergindo das cinzas, a marca desperta para conquistar seu lugar no
              mundo, deixando uma marca memorável em seu caminho.
            </H3>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Container>
  );
}

export default Methodology;
