import React from 'react';

import { Hidden, Stack } from '@mui/material';

import { H1, H3 } from 'components/typography';

function About() {
  return (
    <Stack spacing={3}>
      <H3 color="#1A1A1A">SOBRE /</H3>

      <H1
        color="#1A1A1A"
        sx={{ maxWidth: { xs: '100%', md: 400 }, fontSize: { xs: 32, md: 52 }, lineHeight: 1.3 }}
      >
        PROJETOS COM PERSONALIDADE & MINIMALISTA
      </H1>

      <H3 color="#1A1A1A" fontStyle="italic" fontWeight="normal">
        Ao longo dos anos, tenho tido o privilégio de colaborar com diversas empresas, e juntos,
        temos fortalecido e destacado suas marcas no mercado. Acredito que a identidade visual é um
        elemento fundamental para transmitir os valores e a personalidade de uma empresa, e é por
        isso que coloco meu coração em cada projeto que realizo.
        <Hidden mdUp>
          <br />
          <br />
        </Hidden>
      </H3>

      <Stack direction="row">
        <Hidden mdDown>
          <Stack flex={1} />
        </Hidden>

        <H3 color="#1A1A1A" fontWeight="normal" sx={{ flex: 1 }}>
          Tenho como objetivo atuar de ponta a ponta, garantindo que todos os aspectos do processo
          sejam cuidadosamente planejados e executados para alcançar resultados de alta qualidade.
        </H3>
      </Stack>

      <Stack direction="row">
        <Hidden mdDown>
          <Stack flex={1} />
        </Hidden>

        <Stack spacing={0.4} flex={1}>
          <H3 color="#1A1A1A">Juliano Souza,</H3>

          <H3 color="#1A1A1A" fontWeight="normal">
            Designer especialista em logos <br /> e identidades visuais.
          </H3>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default About;
