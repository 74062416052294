import React, { useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Alert, Container, Grid } from '@mui/material';

import { Form } from '@unform/web';

import CustomTextField from 'components/forms/textField';
import { H1 } from 'components/typography';

import Api from 'services/api';
import Mask from 'utils/mask';
import * as Yup from 'yup';

function Contact() {
  const formRef = useRef();

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  function applyPhoneMask(e) {
    e.currentTarget.value = Mask(e.currentTarget.value, 'phone');
  }

  function handleSubmitForm(formData) {
    setLoading(true);
    setAlert(null);

    validateFields(formData);
  }

  async function validateFields(formData) {
    formRef.current.setErrors({});

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('É obrigatório informar um nome'),
        mail: Yup.string()
          .required('É obrigatório informar um e-mail')
          .email('Informe um e-mail válido'),
        phone: Yup.string().required('É obrigatório informar um telefone'),
        message: Yup.string().required('É obrigatório informar uma mensagem'),
      });

      await schema.validate(formData, { abortEarly: false });

      send(formData);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
        setLoading(false);
      }
    }
  }

  async function send(payload) {
    try {
      const response = await Api.post('contact/send', payload);
      const { message } = response.data;

      formRef.current.reset();

      setAlert({ type: 'success', message });
    } catch (error) {
      setAlert({
        type: 'error',
        message: 'Ops! Ocorreu um erro inesperado, entre em contato com o suporte.',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <H1 color="#1A1A1A" fontSize={34} sx={{ mb: 3 }}>
        Entre em contato
      </H1>

      <Form ref={formRef} onSubmit={handleSubmitForm}>
        <Grid spacing={3} container>
          {alert && (
            <Grid xs={12} item>
              <Alert severity={alert.type}>{alert.message}</Alert>
            </Grid>
          )}

          <Grid xs={12} md={6} item>
            <CustomTextField label="Nome" name="name" />
          </Grid>

          <Grid xs={12} md={6} item>
            <CustomTextField label="E-mail" name="mail" />
          </Grid>

          <Grid xs={12} md={6} item>
            <CustomTextField label="WhatsApp" name="phone" onChange={applyPhoneMask} />
          </Grid>

          <Grid xs={12} md={6} item>
            <CustomTextField label="Empresa" name="company" />
          </Grid>

          <Grid xs={12} item>
            <CustomTextField label="Assunto" name="message" rows={8} multiline />
          </Grid>
        </Grid>

        <LoadingButton
          type="submit"
          variant="contained"
          sx={{
            minWidth: { xs: '100%', md: 200 },
            height: 48,
            borderRadius: '10px',
            fontSize: 18,
            fontWeight: 'bold',
            textTransform: 'none',
            mt: 4,
          }}
          loading={loading}
          disableElevation
        >
          Enviar
        </LoadingButton>
      </Form>
    </Container>
  );
}

export default Contact;
