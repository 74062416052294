import React from 'react';

import { Box } from '@mui/material';

export const H1 = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="h1"
      m={0}
      fontSize="28px"
      fontWeight="600"
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const H2 = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="h2"
      m={0}
      fontSize="24px"
      fontWeight="600"
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const H3 = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="h3"
      m={0}
      fontSize="18px"
      fontWeight="600"
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const H4 = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="h4"
      m={0}
      fontSize="16px"
      fontWeight="500"
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const H5 = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="h5"
      m={0}
      fontSize="14px"
      fontWeight="600"
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const H6 = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="h6"
      m={0}
      fontSize="13px"
      fontWeight="600"
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const Paragraph = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="p"
      m={0}
      fontSize="14px"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const Small = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="small"
      m={0}
      fontSize="12px"
      fontWeight="500"
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const Span = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="span"
      m={0}
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};

export const Tiny = (props) => {
  const { children, ellipsis, textTransform } = props;

  return (
    <Box
      component="small"
      m={0}
      fontSize="11px"
      lineHeight="1.5"
      textTransform={textTransform}
      whiteSpace={ellipsis ? 'nowrap' : 'normal'}
      overflow={ellipsis ? 'hidden' : ''}
      textOverflow={ellipsis ? 'ellipsis' : ''}
      {...props}
    >
      {children}
    </Box>
  );
};
