import React from 'react';
import { Link } from 'react-router-dom';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Container, Stack } from '@mui/material';

import { H1, H2, H4 } from 'components/typography';

function Footer() {
  const links = [
    { title: 'Projetos' },
    { title: 'Sobre' },
    { title: 'Serviços' },
    { title: 'Contatos', path: '/contatos' },
  ];

  function handleClickLink(link) {
    if (!link.path) {
      document.getElementById(link.title).scrollIntoView({
        behavior: 'smooth',
      });
      return;
    }

    window.location.href = link.path;
  }

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 8, md: 10 } }}>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
        <Stack spacing={2}>
          <H1
            color="#1A1A1A"
            sx={{
              maxWidth: { xs: '100%', md: 540 },
              fontSize: { xs: 32, md: 52 },
              lineHeight: 1.3,
              textTransform: 'uppercase',
            }}
          >
            Vamos conversar sobre seu projeto?
          </H1>

          <Link to="/contatos" style={{ display: 'flex', color: '#1A1A1A' }}>
            <H2 color="#1A1A1A">contato@ojstudio.com.br</H2>
            <ArrowOutwardIcon sx={{ ml: 2, mb: '-3px' }} />
          </Link>
        </Stack>

        <Stack spacing={2} sx={{ mt: { xs: 3, md: 0 } }}>
          <H4 color="#707070" fontWeight="bold">
            NAVEGAÇÃO RÁPIDA
          </H4>

          {links.map((data, key) => (
            <Link
              key={key}
              style={{ color: '#1A1A1A', fontWeight: 'bold' }}
              onClick={() => handleClickLink(data)}
            >
              {data.title}
            </Link>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
}

export default Footer;
