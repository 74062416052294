import React from 'react';
import { Link } from 'react-router-dom';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Box, Container, Grid, Hidden, Stack } from '@mui/material';

import { H3, H4 } from 'components/typography';

function Header() {
  return (
    <Container maxWidth="lg">
      <img src="/assets/images/despertando-sonhos.png" />

      <Stack direction="row" justifyContent="space-between" sx={{ my: 3 }}>
        <Hidden mdDown>
          <div />
        </Hidden>

        <Link to="/contatos" style={{ display: 'flex', color: '#FCFCFC' }}>
          <H4 color="#FCFCFC">contato@ojstudio.com.br</H4>
          <ArrowOutwardIcon sx={{ fontSize: 20, height: 28 }} />
        </Link>
      </Stack>

      <Grid id="Projetos" spacing={1} sx={{ mt: 10 }} container>
        <Grid item xs={12} sx={{ mb: 4 }}>
          <H3 color="#FCFCFC">CASES SELECIONADOS /</H3>
        </Grid>

        <Grid xs={12} md={6} item>
          <Box
            position="relative"
            sx={{
              '&:hover div': {
                display: 'flex',
                background: 'rgba(0, 0, 0, .6)',
              },
            }}
          >
            <img
              src="/assets/images/elitepay.jpg"
              style={{
                width: '100%',
                borderRadius: 32,
              }}
            />

            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'none',
                alignItems: 'flex-end',
                p: '30px',
                borderRadius: '32px',
              }}
            >
              <H4 sx={{ color: '#FFF' }}>ELITEPAY</H4>
            </Box>
          </Box>
        </Grid>

        <Grid xs={12} md={6} item>
          <Box
            position="relative"
            sx={{
              '&:hover div': {
                display: 'flex',
                background: 'rgba(0, 0, 0, .6)',
              },
            }}
          >
            <img
              src="/assets/images/lapremoldados.jpg"
              style={{ width: '100%', borderRadius: 32 }}
            />

            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'none',
                alignItems: 'flex-end',
                p: '30px',
                borderRadius: '32px',
              }}
            >
              <H4 sx={{ color: '#FFF' }}>LA PRE-MOLDADOS</H4>
            </Box>
          </Box>
        </Grid>

        <Grid xs={12} md={6} item>
          <Box
            position="relative"
            sx={{
              '&:hover div': {
                display: 'flex',
                background: 'rgba(0, 0, 0, .6)',
              },
            }}
          >
            <img src="/assets/images/mellio.jpg" style={{ width: '100%', borderRadius: 32 }} />

            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'none',
                alignItems: 'flex-end',
                p: '30px',
                borderRadius: '32px',
              }}
            >
              <H4 sx={{ color: '#FFF' }}>MELLIO</H4>
            </Box>
          </Box>
        </Grid>

        <Grid xs={12} md={6} item>
          <Box
            position="relative"
            sx={{
              '&:hover div': {
                display: 'flex',
                background: 'rgba(0, 0, 0, .6)',
              },
            }}
          >
            <img
              src="/assets/images/influencepass.jpg"
              style={{ width: '100%', borderRadius: 32 }}
            />

            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'none',
                alignItems: 'flex-end',
                p: '30px',
                borderRadius: '32px',
              }}
            >
              <H4 sx={{ color: '#FFF' }}>INFLUENCE PASS</H4>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Header;
